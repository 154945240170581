const actions = {
  SET_STATE: 'registration/SET_STATE',
  REFRESH_ERRORS: 'registration/REFRESH_ERRORS',
  DETAILS: 'registration/DETAILS',
  ADD: 'registration/ADD',
  EDIT: 'registration/EDIT',
  CONSENT_DETAILS: 'registration/CONSENT_DETAILS',
  CONSENT_EDIT: 'registration/CONSENT_EDIT',
};

export default actions
