const actions = {
  SET_STATE: 'finalist/SET_STATE',
  REFRESH_ERRORS: 'finalist/REFRESH_ERRORS',
  DETAILS: 'finalist/DETAILS',
  ADD: 'finalist/ADD',
  EDIT: 'finalist/EDIT',
  CONSENT_DETAILS: 'finalist/CONSENT_DETAILS',
  CONSENT_EDIT: 'finalist/CONSENT_EDIT',
};

export default actions
