import apiClient from '../axios';

export async function details(countryId, id) {
  return apiClient
    .get(`/public/country/${countryId}/registrations/${id}`)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}

export async function consentDetails(registration, key) {
  return apiClient
    .get(`/public/registrations/consent/${registration}/${key}`)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}

export async function add(data) {
  return apiClient
    .put(`/public/country/${data.countryId}/registrations`, data)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}

export async function edit(countryId, id, data) {
  return apiClient
    .post(`/public/country/${countryId}/registrations/${id}`, data)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}

export async function editConsent(registration, key, data) {
  return apiClient
    .post(`/public/registrations/consent/${registration}/${key}`, data)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}
