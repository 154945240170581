import apiClient from '../axios'

export async function all() {
  return apiClient
    .get(`/countries/all`)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}
