import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import srTranslation from './translations/rs';
import hrTranslation from './translations/hr';
import baTranslation from './translations/ba';

const resources = {
  rs: {
    translation: srTranslation,
  },
  hr: {
    translation: hrTranslation
  },
  ba: {
    translation: baTranslation
  }
};

const currentTranslation = () =>
  localStorage.getItem('translation')
    ? localStorage.getItem('translation')
    : localStorage.setItem('translation', 'hr');

i18n.use(initReactI18next).init({
  resources,
  lng: currentTranslation(),
  fallbackLng: 'hr',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
