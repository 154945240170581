import actions from './actions'

const initialState = {
  sidebarMini: false,
  sidebarShow: 'responsive'
};

export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.TOGGLE_SIDEBAR:
      return { ...state, sidebarMini: !state.sidebarMini };
    default:
      return state
  }
}
