import apiClient from '../axios';

export async function details(countryId, id) {
  return apiClient
    .get(`/public/country/${countryId}/interstate/${id}`)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}

export async function consentDetails(registration, key) {
  return apiClient
    .get(`/public/interstate/consent/${registration}/${key}`)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}

export async function edit(countryId, id, data) {
  return apiClient
    .post(`/public/country/${countryId}/interstate/${id}`, data)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}

export async function editConsent(registration, key, data) {
  return apiClient
    .post(`/public/interstate/consent/${registration}/${key}`, data)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}
