import React, { Component } from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"/>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Page404 = React.lazy(() => import('./components/errors/Page404'));
const Page500 = React.lazy(() => import('./components/errors/Page500'));

class App extends Component {

  render() {
    return (
      <ConnectedRouter history={this.props.history}>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Registracija" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </ConnectedRouter>
    );
  }
}

export default App;
