import { all } from 'redux-saga/effects'
import country from './country/sagas'
import language from './language/sagas'
import config from './config/sagas'
import registration from './registration/sagas'
import season from './season/sagas'
import sport from './sport/sagas'
import finalist from './finalist/sagas'
import interstate from './interstate/sagas'

export default function* rootSaga() {
  yield all([
    country(),
    language(),
    config(),
    sport(),
    registration(),
    season(),
    finalist(),
    interstate()
  ])
}
