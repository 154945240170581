const actions = {
  SET_STATE: 'interstate/SET_STATE',
  REFRESH_ERRORS: 'interstate/REFRESH_ERRORS',
  DETAILS: 'interstate/DETAILS',
  ADD: 'interstate/ADD',
  EDIT: 'interstate/EDIT',
  CONSENT_DETAILS: 'interstate/CONSENT_DETAILS',
  CONSENT_EDIT: 'interstate/CONSENT_EDIT',
};

export default actions
