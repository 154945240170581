import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as languageService from '../../services/language'
import actions from './actions'

export function* fetchAll() {
  yield put({
    type: 'languages/SET_STATE',
    payload: {
      loadingLanguages: true,
    },
  });

  const response = yield call(languageService.fetchAll);

  if (response) {
    const { data } = response;

    yield put({
      type: 'languages/SET_STATE',
      payload: {
        all: data,
      },
    })
  }

  yield put({
    type: 'languages/SET_STATE',
    payload: {
      loadingLanguages: false,
    },
  })
}

export function* selected({ payload }) {
  localStorage.setItem('translation', payload.language.code);

  yield put({
    type: 'languages/SET_STATE',
    payload: {
      selected: payload.language
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL, fetchAll),
    takeEvery(actions.SELECT, selected),
    fetchAll()
  ])
}
