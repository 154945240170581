import { all, takeEvery, put, call } from 'redux-saga/effects';
import * as seasonService from '../../services/season';
import actions from './actions';
import toastr from 'toastr';
import {history} from "../../index";

export function* active() {
  yield put({
    type: 'season/SET_STATE',
    payload: {
      loadingActive: true,
    },
  });

  const response = yield call(seasonService.active);

  if (response && response.status === 200) {
    const { data } = response;

    yield put({
      type: 'season/SET_STATE',
      payload: {
        active: data.data,
        selected: data.data
      },
    })
  }

  yield put({
    type: 'season/SET_STATE',
    payload: {
      loadingActive: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ACTIVE, active),
    active()
  ])
}
