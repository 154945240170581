import actions from './actions'

const initialState = {
  all: [],
  loadingLanguages: false,
  selected: null
};

export default function languageReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state
  }
}
