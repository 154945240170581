import { all, takeEvery, put, call } from 'redux-saga/effects';
import * as sportService from '../../services/sport';
import actions from './actions';

export function* allSports({ payload }) {
  const { countryId } = payload;

  yield put({
    type: 'sport/SET_STATE',
    payload: {
      loadingAll: true,
    },
  });

  const response = yield call(sportService.all, countryId);

  if (response && response.status === 200) {
    const { data } = response;

    yield put({
      type: 'sport/SET_STATE',
      payload: {
        all: data.data
      },
    })
  }

  yield put({
    type: 'sport/SET_STATE',
    payload: {
      loadingAll: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ALL, allSports)
  ])
}
