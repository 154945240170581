import { all, takeEvery, put, call } from 'redux-saga/effects';
import * as countryService from '../../services/country';
import actions from './actions';

export function* allCountries() {
  yield put({
    type: 'country/SET_STATE',
    payload: {
      loadingAll: true,
    },
  });

  const response = yield call(countryService.all);

  if (response && response.status === 200) {
    const { data } = response;

    const countries = data.data;
    const host = window.location.href;

    let countryId = 2;
    if (host.includes('rs')) {
      countryId = 1;
    } else if (host.includes('hr')) {
      countryId = 2;
    } else {
      countryId = 3;
    }

    const country = countries.find(c => c.id === countryId);
    localStorage.setItem('translation', country.language.code);

    yield put({
      type: 'country/SET_STATE',
      payload: {
        all: data.data,
        selected: country
      },
    });

    yield put({
      type: 'languages/SET_STATE',
      payload: {
        selected: country.language
      },
    })
  }

  yield put({
    type: 'country/SET_STATE',
    payload: {
      loadingAll: false,
    },
  })
}

export function* selected({ payload }) {
  yield put({
    type: 'country/SET_STATE',
    payload: {
      selected: payload.country
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ALL, allCountries),
    takeEvery(actions.SELECT, selected),
    allCountries()
  ])
}
