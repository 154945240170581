import { all, takeEvery, put, call } from 'redux-saga/effects';
import * as registrationService from '../../services/registration';
import actions from './actions';
import toastr from 'toastr';
import {history} from "../../index";

export function* details({ payload }) {
  const { countryId, id } = payload;

  yield put({
    type: 'registration/SET_STATE',
    payload: {
      loadingDetails: true,
    },
  });

  const response = yield call(registrationService.details, countryId, id);

  if (response && response.status === 200) {
    const { data } = response;

    yield put({
      type: 'registration/SET_STATE',
      payload: {
        details: data.data
      },
    })
  } else {
    yield history.push(`/404`);
  }

  yield put({
    type: 'registration/SET_STATE',
    payload: {
      loadingDetails: false,
    },
  })
}

export function* consentDetails({ payload }) {
  const { registration, key } = payload;

  yield put({
    type: 'registration/SET_STATE',
    payload: {
      loadingConsentDetails: true,
    },
  });

  const response = yield call(registrationService.consentDetails, registration, key);

  if (response && response.status === 200) {
    const { data } = response;

    yield put({
      type: 'registration/SET_STATE',
      payload: {
        consent: data.data
      },
    })
  } else {
    yield history.push(`/404`);
  }

  yield put({
    type: 'registration/SET_STATE',
    payload: {
      loadingConsentDetails: false,
    },
  })
}

export function* add({ payload }) {
  yield put({
    type: 'registration/SET_STATE',
    payload: {
      sendingRegistration: true,
    },
  });

  const response = yield call(registrationService.add, payload);

  if (response && response.status >= 200 && response.status <= 201) {
    const { data } = response;

    yield put({
      type: 'registration/SET_STATE',
      payload: {
        details: data.data
      },
    })



    yield history.push(`/${payload.locale}/hvala`);
  }

  if (response && response.status >= 400 && response.status <= 422) {
    if (response.data.errors) {
      yield put({
        type: 'registration/SET_STATE',
        payload: {
          errors: response.data.errors,
        }
      });
    }
  }

  yield put({
    type: 'registration/SET_STATE',
    payload: {
      sendingRegistration: false,
    },
  });
}

export function* edit({ payload }) {
  yield put({
    type: 'registration/SET_STATE',
    payload: {
      updatingRegistration: true,
    },
  });

  const { countryId, id, data } = payload;
  const response = yield call(registrationService.edit, countryId, id, data);

  if (response && response.status >= 200 && response.status <= 201) {
    const { data } = response;

    toastr.success(data.message, '', { positionClass: "toast-bottom-right", tapToDismiss: true });
  }

  if (response && response.status >= 400 && response.status <= 422) {
    if (response.data.errors) {
      yield put({
        type: 'registration/SET_STATE',
        payload: {
          errors: response.data.errors,
        }
      });
    }
  }

  yield put({
    type: 'registration/SET_STATE',
    payload: {
      updatingRegistration: false,
    },
  });
}

export function* editConsent({ payload }) {
  yield put({
    type: 'registration/SET_STATE',
    payload: {
      updatingConsent: true,
    },
  });

  const { registration, key, data } = payload;
  const response = yield call(registrationService.editConsent, registration, key, data);

  if (response && response.status >= 200 && response.status <= 201) {
    const { data } = response;

    toastr.success(data.message, '', { positionClass: "toast-bottom-right", tapToDismiss: true });
  }

  if (response && response.status >= 400 && response.status <= 422) {
    if (response.data.errors) {
      yield put({
        type: 'registration/SET_STATE',
        payload: {
          errors: response.data.errors,
        }
      });
    }
  }

  yield put({
    type: 'registration/SET_STATE',
    payload: {
      updatingConsent: false,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.DETAILS, details),
    takeEvery(actions.CONSENT_DETAILS, consentDetails),
    takeEvery(actions.ADD, add),
    takeEvery(actions.EDIT, edit),
    takeEvery(actions.CONSENT_EDIT, editConsent)
  ])
}
