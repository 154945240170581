import apiClient from '../axios';

export async function all(countryId) {
  return apiClient
    .get(`/country/${countryId}/sports/activated`)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}
