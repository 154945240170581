import actions from './actions'

const initialState = {
  consent: {},
  details: {},
  loadingConsent: false,
  loadingDetails: false,
  sendingRegistration: false,
  updatingRegistration: false,
  updatingConsent: false,
  errors: {},
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state
  }
}
