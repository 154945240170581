import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import country from './country/reducers'
import language from './language/reducers'
import config from './config/reducers'
import registration from './registration/reducers'
import season from './season/reducers'
import sport from './sport/reducers'
import finalist from './finalist/reducers'
import interstate from './interstate/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    country,
    language,
    config,
    sport,
    registration,
    finalist,
    interstate,
    season
  })
