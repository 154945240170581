import apiClient from '../axios'

export async function active() {
  return apiClient
    .get(`/seasons/active`)
    .then(response => {
      if (response) {

        return response
      }
      return false
    })
    .catch(err => err && err);
}
