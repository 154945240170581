import apiClient from '../axios'

export async function fetchAll() {
  return apiClient
    .get('/languages')
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

